import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isVisible) = $event)),
      _ctx.updateVisibility
    ],
    timeout: 10000,
    "multi-line": "",
    color: "secondary"
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        icon: "",
        variant: "text",
        onClick: _withModifiers(_ctx.acknowledge, ["stop"]),
        size: "small"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { "data-testid": "close" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.mdiCloseIcon), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentToUse), {
            key: 0,
            error: _ctx.error,
            onReset: _ctx.reset
          }, null, 40, ["error", "onReset"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}