import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LegacyBrowserNotification = _resolveComponent("LegacyBrowserNotification")!
  const _component_DeprecatedRedirectNotification = _resolveComponent("DeprecatedRedirectNotification")!
  const _component_GpHeader = _resolveComponent("GpHeader")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_CustomTransition = _resolveComponent("CustomTransition")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_FullHeight = _resolveComponent("FullHeight")!

  return (_openBlock(), _createBlock(_component_FullHeight, null, {
    default: _withCtx(() => [
      _createVNode(_component_LegacyBrowserNotification),
      _createVNode(_component_DeprecatedRedirectNotification),
      _createVNode(_component_GpHeader, {
        "onToggle:drawer": _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = !_ctx.drawer)),
        drawerOpen: _ctx.drawer
      }, null, 8, ["drawerOpen"]),
      _createVNode(_component_Navigation, {
        "is-visible": _ctx.drawer,
        "onToggle:drawer": _ctx.toggleDrawer,
        user: _ctx.user,
        logout: _ctx.logout,
        login: _ctx.login
      }, null, 8, ["is-visible", "onToggle:drawer", "user", "logout", "login"]),
      _createVNode(_component_v_main, { class: "d-flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            class: _normalizeClass([{ 'is-legacy-route': _ctx.isLegacyRoute }, "wrapper center-horizontally"]),
            fluid: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_router_view, {
                key: _ctx.$route.params.viewId
              }, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_component_CustomTransition, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }))
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            error: _ctx.error,
            "error-is-intrusive": _ctx.errorIsIntrusive,
            setError: _ctx.setError
          }, null, 8, ["error", "error-is-intrusive", "setError"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}