
  import { defineComponent } from 'vue';
  import UserFriendlyErrorMessage from '@/common/components/ErrorMessage/components/UserFriendlyErrorMessage.vue';
  import GenericErrorMessage from '@/common/components/ErrorMessage/components/GenericErrorMessage.vue';
  import UserFriendlyError from '@/common/utils/UserFriendlyError';
  import HttpError from '@/api/utils/HttpError';
  import ChunkLoadError from '@/common/utils/ChunkLoadError';
  import ChunkLoadErrorMessage from '@/common/components/ErrorMessage/components/ChunkLoadErrorMessage.vue';
  import HttpErrorMessage from '@/common/components/ErrorMessage/components/HttpErrorMessage.vue';
  import { mdiCloseThick } from '@mdi/js';

  export default defineComponent({
    props: {
      error: Error,
      errorIsIntrusive: {
        type: Boolean,
        default: false,
      },
      setError: {
        type: Function,
        default: function () {},
      },
    },
    data() {
      return {
        isVisible: false,
      };
    },
    watch: {
      error: {
        handler(newVal) {
          if (newVal !== null) {
            // we show only if error is not intrusive (intrusive errors are handled elsewhere)
            this.isVisible = !!newVal && !this.errorIsIntrusive;
          }
        },
        immediate: true,
      },
      'error.message': {
        handler(newVal) {
          if (newVal) {
            if (process.env.NODE_ENV === 'development') {
              // eslint-disable-next-line no-console
              console.error(newVal.toString());
            }
          }
        },
        immediate: true,
      },
      '$route.path': {
        handler(newVal) {
          if (newVal) {
            // user browsed away, reset error
            this.isVisible = false;
            this.setError(null);
          }
        },
        immediate: false,
      },
    },
    computed: {
      mdiCloseIcon() {
        return mdiCloseThick;
      },
      componentToUse() {
        switch (true) {
          case this.error instanceof UserFriendlyError:
            return UserFriendlyErrorMessage;
          case this.error instanceof HttpError:
            return HttpErrorMessage;
          case this.error instanceof ChunkLoadError:
            return ChunkLoadErrorMessage;
          default:
            return GenericErrorMessage;
        }
      },
    },
    methods: {
      acknowledge() {
        this.isVisible = false;
        this.setError(null);
      },
      updateVisibility(value: boolean) {
        if (value === false) {
          this.setError(null);
        }
      },
      reset() {
        this.isVisible = false;
        this.setError(null);
      },
    },
    beforeUnmount(): void {
      this.setError(null);
    },
  });
