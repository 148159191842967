import { CustomTheme } from '@/pages/Oidc/store/types';

export enum UserLevel {
  User = 0,
  ChiefUser = 10,
  ProjectLeader = 50,
  Manager = 100,
  Admin = 101,
}

export interface IUser {
  fullName: string;
  nick: string;
}

export type RootState = {
  error: null | Error;
  errorIsIntrusive: boolean;
  isInitialPageLoad: boolean;
  locale: string | null;
  userLevel: UserLevel | null;
  user: IUser | null;
  appVersion: string;
  impersonator: string | null; // username of impersonator
  customization: CustomTheme | null; // The name of the custom theme to use for a specific customer
  preferredTimezone: string | null; // IANA time zone name
};

export interface ISetErrorMutation {
  error: Error;
  intrusive?: boolean;
}
