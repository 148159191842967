
  import { defineComponent } from 'vue';
  import { mapActions, mapState } from 'pinia';

  import Navigation from '@/pages/Navigation/views/Navigation.vue';
  import GpHeader from '@/pages/Header/Header.vue';
  import { AppData } from '@/pages/App/App.types';
  import ErrorMessage from '@/common/components/ErrorMessage/ErrorMessage.vue';
  import CustomTransition from '@/common/components/CustomTransition/CustomTransition.vue';
  import FullHeight from '@/common/components/FullHeight/FullHeight.vue';
  import { RouteNames } from '@/router/types';
  import LegacyBrowserNotification from '@/common/components/LegacyBrowserNotification/LegacyBrowserNotification.vue';
  import DeprecatedRedirectNotification from '@/pages/App/components/DeprecatedRedirectNotification/DeprecatedRedirectNotification.vue';
  import { useRootStore } from '@/store';
  import { useAuthStore } from '@/pages/Oidc/store';

  export default defineComponent({
    name: 'App',
    components: {
      DeprecatedRedirectNotification,
      Navigation,
      GpHeader,
      ErrorMessage,
      CustomTransition,
      FullHeight,
      LegacyBrowserNotification,
    },
    data: () =>
      ({
        drawer: null,
      }) as AppData,
    methods: {
      toggleDrawer(value: boolean): void {
        if (this.drawer !== value) {
          this.drawer = value;
        }
      },
      ...mapActions(useAuthStore, ['logout', 'login']),
      ...mapActions(useRootStore, ['setError']),
    },
    computed: {
      isLegacyRoute() {
        const legacyRoutes: string[] = [
          RouteNames.StationInfo,
          RouteNames.StationSms,
          RouteNames.Profile,
          RouteNames.Export,
        ];
        return legacyRoutes.includes(this.$route.name as string);
      },
      ...mapState(useRootStore, ['user', 'error', 'errorIsIntrusive']),
    },
  });
